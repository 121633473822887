export const emptyUrl = "javascript:void(0)";
//export const emptyUrl = "";
//export const subDomain = "";
export const subDomain = "dev.";

export const appConfig = {
    ssr: false,
    switchUrl: `https://${subDomain}softex.nl`,
    defaultContent: "welcome",
    startYear: 2010,
    sideBars: true,
    nightMode: false, visualizeMode: true,
    session: true,
    canEmptyCache: true,
    returnToReferer: false,
    authorizationUrl: "https://authorization-api.softex.nl/authorization",
    mailUrl: "https://authorization-api.softex.nl/authorization/mail",
    applicationName: "Softex App",
    author: "Eric Gehring, Softex",
    keywords: "Softex, We automate automation, Eric Gehring, software developer, nederland, limburg, parkstad, landgraaf, node.js, typescript, SQL, react, remix, angular, .NET"
}

// connection Config

export const enum connectionImage {
    cloud, wifi, auto 
} 

export const connectionConfig = {   
    enabled: true,
    urlCheck: "https://softex.nl/helloworld",
    imaging: connectionImage.wifi
}

// Resources

export const i18nClientStepsConfig = {
    embedded: false,
    defaultSignature: "/{{lng}}/{{ns}}.json",
    cache: {
        enabled: true,
        prefix: "cached-locales-",
        defaultVersion: "1.0.0.18",
        versions: {},
        expirationTime: 90 * 24 * 60 * 60 * 1000,
        session: false // Session or LocalStorage
    },
    externalServer:{
        enabled: true,
        signature: undefined,
        url: `https://${subDomain}softex.nl`,
        path: "/locales_cache",
    },
    fallback: {
        enabled: true,
        useCache: true,
        signature: undefined,
        path: "./json"
    }
}
